module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":810,"showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","plugins":[null]}],"fields":[{"name":"title","store":true,"attributes":{"boost":2000}},{"name":"description","store":true,"attributes":{"boost":1000}},{"name":"type","store":true},{"name":"url","store":true}],"resolvers":{"MarkdownRemark":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"},"includeOptions":{"include":["^/en/blog","^/en/category","^/admin"],"exclude":["^/$"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-indicator/gatsby-browser.js'),
      options: {"plugins":[],"color":"#4CB461","height":"4px","paths":["/blog/*","!/blog/tags*","!/blog/page*"],"zIndex":"18"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/opt/build/repo/src/cms/cms.ts","enableIdentityWidget":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
